import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AppServices from '../../services/AppService';

const inititalState = {
    listAppLoading: false,
    listAppByKeyword: null,
    listAppError: null,
    currency: 'USD',
};

export const asyncGetListApp = createAsyncThunk(
    'app/getListApp',
    async ({ keys = [
        'edu_login_detail',
        'edu_login_bg_mobile',
        'edu_login_bg_desktop',
        'profile-completion',
        'verification_exclude_list',
        'all',
    ], startWith = null }) => {
        const key = `${keys
            .map((k) => k.slice(0, 2))
            .join('') + (startWith ? `${startWith?.slice(0, 2)}` : '')}_appc`;
        const timestamp = localStorage.getItem(`${key}ts_appc`);
        if (timestamp && new Date().getTime() - timestamp < (1000 * 60 * 60)) {
            const listApp = JSON.parse(localStorage.getItem(key));
            return { listApp, startWith };
        }

        const listApp = await AppServices.getListApp({});
        localStorage.setItem(key, JSON.stringify(listApp));
        localStorage.setItem(`${key}ts_appc`, new Date().getTime());
        return { listApp, startWith };
    },
);

export const appSlice = createSlice({
    name: 'app',
    initialState: inititalState,
    reducers: {
        resetListApp: () => ({ ...inititalState }),
        setCurrency: (state, { payload }) => {
            state.currency = payload;
        },
        removeListAppByKeyword: (state, { payload }) => {
            Object.keys(state.listAppByKeyword).forEach((key) => {
                if (key.includes(payload)) {
                    delete state.listAppByKeyword[key];
                }
            });
        },
    },
    extraReducers: ({ addCase }) => {
        addCase(asyncGetListApp.pending, (state) => {
            state.listAppLoading = true;
        });
        addCase(asyncGetListApp.fulfilled, (state, { payload }) => {
            if (typeof payload.listApp === 'object') {
                const allowedKeys = [
                    // MANDATORY CONFIG
                    'edu_login_detail',
                    'edu_login_bg_mobile',
                    'edu_login_bg_desktop',
                    'profile-completion',
                    'verification_exclude_list',
                    'grace_period_monthly',
                    'promo_popup',
                    'promo_banner',
                    'ads_object_preview',
                ];
                const newKeywords = payload.listApp?.reduce((acc, app) => {
                    const { key, ...rest } = app;
                    if (allowedKeys.some((allowedKey) => key.includes(allowedKey))) {
                        acc[key] = rest;
                    }
                    return acc;
                }, {});

                const oldKeywordsWithoutPromo = Object.keys(state.listAppByKeyword || {})
                    .filter(
                        (key) => !key.includes('promo_banner') && !key.includes('promo_popup'),
                    )
                    .filter((key) => (payload.startWith ? !key.includes(payload.startWith) : true))
                    .reduce((acc, key) => {
                        acc[key] = state.listAppByKeyword[key];
                        return acc;
                    }, {});

                state.listAppByKeyword = {
                    ...oldKeywordsWithoutPromo,
                    ...newKeywords,
                };
            }
            state.listAppLoading = false;
        });
        addCase(asyncGetListApp.rejected, (state, { payload }) => {
            state.listAppError = payload;
            state.listAppLoading = false;
        });
    },
});

export const { resetListApp, setCurrency, removeListAppByKeyword } = appSlice.actions;

export const appState = (state) => state.app;

const appReducer = appSlice.reducer;

export default appReducer;
